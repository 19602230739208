<template>
  <v-container fluid>

        <!--    <data-card :data="conversation.lead" caption="info"/>-->
        <message-item
            v-for="item in orderedMessages"
            :key="item.id"
            :message="item"
            :contact-name="conversation.contactName"
            :conversation="conversation"
        />

  </v-container>
</template>

<script>
import MessageItem from "./MessageItem.vue";
import tools from "@/util/tools";

export default {
  name: "MessageList",
  components: {MessageItem},
  props: ["conversation"],
  data() {
    return {
      itemHeight: 35,
    };
  },
  computed: {
    orderedMessages() {
      console.log("Sorting Conversation: ", this.conversation);
      let result = tools.sortedMessages(this.conversation);
      return result;
    },
  },
};
</script>

<style scoped></style>
