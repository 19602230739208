<template>

    <v-menu>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          color="grey"
          v-on="on"
        >
             {{ name }}
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="handleClick(index)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
import tools from "@/util/tools";
import backendApi from "@/api/backend-api";
import { mapActions } from "vuex";

export default {
  props: ["name", "phone"],
  data: () => ({
    items: [
      // {
      //   title: "Edit",
      //   icon: "mdi-pencil",
      //   click() {
      //     console.log("edit");
      //   },
      // },
      // {
      //   title: "Due Date",
      //   icon: "mdi-calendar",
      //   click() {
      //     console.log("due date");
      //   },
      // },
      // {
      //   title: "Delete",
      //   icon: "mdi-delete",
      //   click() {
      //     this.$store.dispatch("deleteTask", 1);
      //   },
      // },
      {
        title: "Copy",
        icon: "mdi-content-copy",
        click() {
          tools.copyTextToClipboard(this.name);
          this.$store.dispatch("snackbar/setSnackbar", {
            data: {
              "Copied" : this.name
            },
            color: "blue",
          });
        },
      },
      {
        title: "Open",
        icon: "mdi-walk",
        click() {
          let target = `http://${this.name}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "Whois",
        icon: "mdi-comment-question-outline",
        async click() {
          let whoisRequest = {
            name: this.name,
            deep: true,
          };
          let response = await backendApi.whois(whoisRequest);
          const snack = {
            data: response.data,
            color: "blue",
            timeout: 10000,
            whois: true,
          };
          this.$store.dispatch("snackbar/setSnackbar", snack);
        },
      },
      {
        title: "BuiltWith",
        icon: "mdi-hammer",
        click() {
          let target = `https://builtwith.com/${this.name}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "Call",
        icon: "mdi-phone-outgoing",
        async click() {
          const payload = {
            data: {
              'Calling to' : this.name,
            },
            color: "blue",
            timeout: 10000,
          };
          this.$store.dispatch("snackbar/setSnackbar", payload);
          this.placeCallAction(this.phone);
        },
      },
      {
        title: "Remind",
        icon: "mdi-calendar",
        click() {
          const payload = {
            data: this.conversation,
            color: "blue",
            timeout: 30000,
            reminder: true,
          };
          this.$store.dispatch("snackbar/setSnackbar", payload);
        },
      },
      {
        title: "MOZ",
        icon: "mdi-alpha-m-box",
        async click() {

          let payload = {

            color: "green",
            timeout: 10000,
            whois: true,
          };

          try {
            let response = await backendApi.getMozURLData(this.name);
            payload.data = tools.filterOut(response.data, 0);
            this.$store.dispatch("snackbar/setSnackbar", payload);

          } catch (ex) {
            console.log(ex);
          }

        },
      },
    ],
  }),
  methods: {
    ...mapActions("conversations", [
      "placeCallAction",
      "hideConversationAction",
      "updateAction",
    ]),
    handleClick(index) {
      this.items[index].click.call(this);
    },
  },
};
</script>

<style></style>
