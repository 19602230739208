<template>
  <v-menu
      color="primary"
      v-model="menu"
      :close-on-content-click="true"
  >
    <template #activator="{ on, attrs }">

      <v-btn
          v-bind="attrs"
          color="primary"
          v-on="on"
          @click="evaluate()"
      >

        {{ price ? `$${price}` : 'evaluate' }}
      </v-btn>
    </template>
<!--    <div v-for="val in Object.keys(evaluation)" :key="val + Math.random()">{{ val }}:
      {{ evaluation[val] }}
    </div>-->
    <v-list>
      <v-list-item
          v-for="(item, index) in evaluation"
          :key="index"
      >
        <v-list-item-title>{{index}} - {{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

</template>

<script>
import api from "@/api/backend-api";

export default {
  props: ["domainName", "suggestedPrice"],
  data: () => ({
    loading: false,
    price: '',
    evaluation: {},
    menu: false,
  }),
  computed:{

  },
  mounted() {
    this.price = this.suggestedPrice;
  },
  methods: {
    async evaluate() {
      this.loading = true;
      const {data} = await api.evaluate(this.domainName);
      this.price = data.price;
      this.evaluation = data;
      this.loading = false;
    },
  },
};
</script>

<style></style>
