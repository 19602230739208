<template>
  <v-menu>
    <template #activator="{ on, attrs }">
      <!-- <v-btn v-bind="attrs" v-on="on" color="primary" icon> -->
      <v-btn
          v-bind="attrs"
          color="primary"
          v-on="on"
          fab
          x-small
      >
        <v-icon>mdi-comment</v-icon>

      </v-btn>
    </template>

    <v-list>
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="handleClick(item)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"/>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>

  </v-menu>
</template>

<script>
import backendAPI from "@/api/backend-api";

export default {
  name: "ResponseSuggestionMenu",

  props: ["conversation"],
  data: () => ({
    items: [
      {
        title: "Say Hi!",
        icon: "mdi-om",
        name: "INITIAL"
      },
      {
        title: "Email Initial",
        icon: "mdi-email",
        name: "INITIAL_EMAIL"
      },
      {
        title: "Email Listed",
        icon: "mdi-email",
        name: "ALREADY_LISTED_EMAIL"
      },
      {
        title: "Email Follow up",
        icon: "mdi-email",
        name: "FOLLOW_UP_EMAIL"
      },
      {
        title: "How Much?",
        icon: "mdi-currency-usd",
        name: "HOW_MUCH"
      },
      {
        title: "Who is it?",
        icon: "mdi-account",
        name: "WHO_IS_IT"
      },
      {
        title: "Thanks for Email",
        icon: "mdi-email",
        name: "EMAIL"
      },
      {
        title: "Expensive",
        icon: "mdi-currency-usd-off",
        name: "EXPENSIVE"
      },
      {
        title: "Schedule",
        icon: "mdi-credit-card-clock",
        name: "SCHEDULE"
      },
      {
        title: "No Thanks",
        icon: "mdi-google-downasaur",
        name: "NO_THANKS"
      }
    ],
  }),

  methods: {

    async handleClick(item) {
      console.log("Asking for  suggestion", this.conversation.id);
      const {data} = await backendAPI.getSuggestion({
        id: this.conversation.id,
        classification: item.name
      });
      console.log("Received suggestion", data);
      this.$emit('updateText', data);
    },
  },

};
</script>

<style></style>
