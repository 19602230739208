<template>
  <div>
    <v-btn
        color="primary"
        dark
        transition="slide-x-transition"
        @click="dialog=true"
    >
      {{ externalSiblingsCount ? externalSiblingsCount : "SCANS" }}
    </v-btn>
    <v-dialog
        v-model="dialog"
    >
      <v-card>
        <domain-details-card
            :domain-name="domainName"
            :reload="true"
        />
        <v-card-actions>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import ScanResultCard from "./ScanResultCard";

import DomainDetailsCard from "./DomainDetailsCard.vue";

export default {
  components: {DomainDetailsCard},
  props: ["domainName", "externalSiblingsCount"],
  data: () => ({
    dialog: false,
  }),
};
</script>

<style></style>
