<template>

      <v-btn
          tile
          :color="parked?'red':'green'"
      >
      {{ip}}
      </v-btn>


</template>

<script>
export default {
  props: ["ip", "parked"],
};
</script>

<style></style>
