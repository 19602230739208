<template>
  <v-menu>
    <template #activator="{ on, attrs }">
      <!-- <v-btn v-bind="attrs" v-on="on" color="primary" icon> -->
      <v-btn
          v-bind="attrs"
          color="primary"
          v-on="on"
      >
        {{ name? name.substring(0, name.length-4): "NO NAME" }}
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="handleClick(index)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"/>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>

  </v-menu>
</template>

<script>
import tools from "@/util/tools";
import backendApi from "@/api/backend-api";

export default {
  name: "DomainMenu",

  props: ["name"],
  data: () => ({
    items: [
      // {
      //   title: "Edit",
      //   icon: "mdi-pencil",
      //   click() {
      //     console.log("edit");
      //   },
      // },
      // {
      //   title: "Due Date",
      //   icon: "mdi-calendar",
      //   click() {
      //     console.log("due date");
      //   },
      // },
      // {
      //   title: "Delete",
      //   icon: "mdi-delete",
      //   click() {
      //     this.$store.dispatch("deleteTask", 1);
      //   },
      // },
      {
        title: "Copy",
        icon: "mdi-content-copy",
        click() {
          tools.copyTextToClipboard(this.name);
          this.$store.dispatch("snackbar/setSnackbar", {
            data: {
              "Copied": this.name,
            },
            color: "blue",
          });
        },
      },
      {
        title: "Open",
        icon: "mdi-walk",
        click() {
          let target = `http://${this.name}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "BuiltWith",
        icon: "mdi-hammer",
        click() {
          let target = `https://builtwith.com/${this.name}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "DAN",
        icon: "mdi-currency-usd",
        click() {
          let target = `https://dan.com/orders/checkout/${this.name}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "GoDaddy",
        icon: "mdi-currency-usd",
        click() {
          let target = `https://www.godaddy.com/domainsearch/find?checkAvail=1&segment=repeat&domainToCheck=${this.name}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "SpyFu",
        icon: "mdi-incognito",
        click() {
          let target = `https://www.spyfu.com/overview/domain?query=${this.name}&v2`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "Web Archive",
        icon: "mdi-archive",
        click() {
          let target = `https://web.archive.org/web/*/${this.name}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "Google Translate",
        icon: "mdi-translate",
        click() {
          let target = `https://translate.google.com/?sl=de&tl=ru&text=${this.name.substr(0, this.name.indexOf('.'))}&op=translate`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "Google Search",
        icon: "mdi-search-web",
        click() {
          let target = `https://www.google.com/search?q=${this.name.substr(0, this.name.indexOf('.'))}`;
          window.open(target, "_blank", "noreferrer");
        },
      },
      {
        title: "Whois",
        icon: "mdi-comment-question-outline",
        async click() {
          let whoisRequest = {
            name: this.name,
            deep: false,
          };

          let response = "No Match";
          let payload = {
            data: {
              "Response": response,
            },
            color: "green",
            timeout: 10000,
            whois: true,
          };

          try {
            response = await backendApi.whois(whoisRequest);
            payload.data = response.data;
          } catch (ex) {
            const {response} = ex;
            const {request, ...errorObject} = response; // take everything but 'request'
            console.log(errorObject);
          }

          this.$store.dispatch("snackbar/setSnackbar", payload);
        },
      },
      {
        title: "MOZ",
        icon: "mdi-alpha-m-box",
        async click() {

          let payload = {

            color: "green",
            timeout: 10000,
            whois: true,
          };

          try {
            let response = await backendApi.getMozURLData(this.name);
            payload.data = tools.filterOut(response.data, 0);
            this.$store.dispatch("snackbar/setSnackbar", payload);

          } catch (ex) {
            console.log(ex);
          }

        },
      },

    ],
  }),

  methods: {

    handleClick(index) {
      this.items[index].click.call(this);
    },
  },

};
</script>

<style></style>
