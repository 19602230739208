<template>
  <p class="time font-weight-bold"
     v-bind:class="{oldText: isOld}"
     v-if="date">
    {{ displayDate }}
  </p>
</template>

<script>
import moment from "moment";
import tools from "@/util/tools";

export default {
  name: "DateDisplay",
  props: ["date", "dateFormat"],

  computed: {
    displayDate() {
      return new moment(this.date).format(this.dateFormat);
    },

    isOld(){
      const lastYear = tools.subtractYears(1);
      return  new Date(this.date)<lastYear;
    }

  },
};
</script>

<style lang="scss" scoped>

.time {
  padding: 0px;
  margin: 0px;
  font-size: 12px;
}
.oldText {
  background-color: #90a4ae;
}

</style>
