<template>
  <v-container :class="getClass" fluid>

    <v-row no-gutters justify="space-between" class="fill-width">
      <v-col>

        <v-tooltip bottom offset-overflow>
          <template #activator="{ on }">
            <v-avatar :color="getColor"
                      @click="copyName"
                      v-on="on" size="20" class="white--text pa-0">
              {{ getInitials }}
            </v-avatar>
          </template>
          <span>{{ this.getName }}</span>
        </v-tooltip>
      </v-col>

      <v-col>
          <date-display
              :date="message.dateCreated ? message.dateCreated : ''"
              date-format="YYYY MMM DD HH:mm:ss"
          />
      </v-col>
      <v-col>
          <SMSStatus :status="message.status" :error="message.errorCode"/>
      </v-col>
      <v-col>
          <v-select
              v-if="!message.user"
              v-model="messageClass"
              class="mx-2 pa-0"
              :items="messageClassification"
              hide-details
              label="Classify"
              single-line
          />
      </v-col>


    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card v-if="message.type === 'RECORDING'">
          <v-card-title>VOICE MAIL</v-card-title>
          <v-card-text>
            {{ message.text }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleClick(message.src)"
            >Listen
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else-if="message.type === 'MEDIA'">
          <v-card-text>{{ message.text }}</v-card-text>
          <v-card-actions>
            <v-btn @click="handleClick(message.src)"
            >Peek
            </v-btn>
          </v-card-actions>


        </v-card>

        <p v-else :class="getTextClass">
          {{ message.text }}
        </p>

      </v-col>


    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import tools from "@/util/tools";

export default {
  name: "MessageItem",
  components: {
    DateDisplay: () => import("@/components/DateDisplay"),
    SMSStatus: () => import("@/components/message/SMSStatus"),
  },
  props: ["message", "contactName", "user"],
  computed: {
    ...mapState("messages", ["messageClassification"]),
    getColor() {
      return this.message.user ? "green" : "red";
    },
    getClass() {
      return this.simple ? "" : this.message.user ? "user" : "client";
    },
    getTextClass() {
      return this.simple ? "simpleText" : "regularText";
    },
    getInitials() {
      let name = "";
      if (this.message.contactName) {
        name = this.message.contactName;
      } else {
        name = this.contactName ? this.contactName : "No Name";
      }

      if (name) {
        return name
            .split(" ")
            .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
            .join("");
      } else {
        return "L";
      }
    },
    getName() {
      return this.message.contactName ? this.message.contactName : "No Name";
    },
    messageClass: {
      get() {
        return this.message.classification;
      },
      set(value) {
        if (value === undefined) return;
        console.log("Event", value, "class", value);
        let payload = {
          id: this.message.id,
          classification: value,
        };
        console.log("Payload For UPDATE", payload);

        this.$store.dispatch("messages/updateMessageClassificationAction", payload);
      },
    },
  },
  methods: {
    updateClass(event) {
    },
    handleClick(src) {
      window.open(src, "_blank", "noreferrer");
    },
    async copyName() {
      await tools.copyTextToClipboard(this.getName);

    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  border: 2px solid rgb(12, 108, 163);
  padding-left: 0px;
  border-radius: 15px;
  margin-top: 1px;
  margin-bottom: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.client {
  padding-left: 0px;
  border: 2px solid rgb(208, 169, 169);
  border-radius: 15px;
  margin-top: 1px;
  margin-bottom: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.simpleText {
  font-weight: bold;
}

.regularText {
  font-weight: normal;
}

.fill-width {
  flex-wrap: nowrap;
}
</style>
