<template>
  <div>
    <v-data-table
        :items="scans"
        :headers="headers"
        :loading="loading"
        loading-text="Loading... Please wait"
        @dblclick:row="(item, slot) => slot.expand(!slot.isExpanded)"
        :expanded.sync="expanded"
        :items-per-page="scans.length"

    >
      d
      <template #[`item.domainName`]="{ item }">
        <a
            :href="`http://${item.domainName}`"
            target="_blank"
        >{{
            item.domainName
          }}</a>
      </template>
      <template #[`item.scanTime`]="{ item }">
        <date-display
            :date="item.scanTime"
            date-format="DD MMM YYYY"
        />
      </template>
      <template #[`item.creation`]="{ item }">
        <date-display
            :date="item.creation"
            date-format="DD MMM YYYY"
        />
      </template>

      <template #[`item.A`]="{ item }">
        <IPBadge :ip="a" :parked="item.parked"/>
      </template>

      <template #[`item.phones`]="{ item }">
        <div v-for="phone in item.phones" :key="phone.number+Math.random()">
          <div v-for="(value, name, index) in phone" :key="index">
            {{ name }}: {{ value }}
          </div>
        </div>
      </template>
      <template #[`item.emails`]="{ item }">
        <div v-for="email in item.emails" :key="email.email+Math.random()">
          <div v-for="(value, name, index) in email" :key="index">
            {{ name }}: {{ value }}
          </div>
        </div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import DateDisplay from "@/components/DateDisplay";
import tools from "@/util/tools";
import api from "@/api/backend-api";
import {mapActions, mapGetters} from "vuex"
import IPBadge from "@/components/IPBadge.vue";

export default {
  components: {
    IPBadge, DateDisplay
  },
  props: ["domainName", "reload", "incoming"],
  data: () => ({
    headers: [
      {text: "Name", value: "domainName"},
      {text: "Scan Time", value: "scanTime"},
      {text: "Created", value: "creation"},
      {text: "A", value: "a"},
      {text: "MX", value: "mx"},
      {text: "NS", value: "ns"},
      {text: "Title", value: "title"},
      {text: "Country", value: "country"},
      {text: "Phones", value: "phones"},
      {text: "Emails", value: "emails"},
      {text: "Load Time", value: "loadTime"}
    ],
    error: null,
    domain: null,
    loading: true,
    expanded: [],


  }),
  computed: {

    ...mapGetters("domains", ["getDomainByName"]),
    ...mapGetters("conversations", ["getConversationByPhone"]),


    scans() {

      let scansArray = this.domain
          ? [

            ...this.domain.siblings.map((s) => s.scanResults).flat(1)

          ]
          : [];

      if(scansArray.length > 0) {
        let scans = [... new Map(scansArray.map(item =>
            [item['domainName'].toLowerCase(), item])).values()];

        return scans.map((scan) => {
          return{
            domainName: scan.domainName,
            scanTime: scan.scanTime,
            creation: this.getCreation(scan),
            phones: this.getPhones(scan),
            emails: this.getEmails(scan),
            loadTime: this.getLoadDuration(scan),
            a: this.getA(scan),
            mx: this.getMX(scan),
            title: this.getTitle(scan),
            ns: this.getNS(scan),
            country: this.getCountry(scan),
          }
        })
      }

      return [];
    },
  },
  methods: {
    ...mapActions("conversations", ["loadConversationsByDomainNameAction"]),

    getPhones(scanResult) {
      return tools.fetchPhones(scanResult);
    },
    getEmails(scanResult) {
      return tools.fetchEmails(scanResult);
    },
    getLoadDuration(scanResult) {
      return tools.getAverageLoadDuration(scanResult);
    },
    getA(scanResult) {
      return tools.fetchA(scanResult);
    },
    getMX(scanResult) {
      return tools.fetchMX(scanResult);
    },
    getTitle(scanResult) {
      return tools.fetchTitle(scanResult);
    },
    getNS(scanResult) {
      return tools.fetchNS(scanResult);
    },
    getCountry(scanResult) {
      return tools.fetchCountry(scanResult);
    },
    getCreation(scanResult) {
      return tools.getCreation(scanResult);
    }

  },
  async mounted() {
    if (!this.domain) {
      this.loading = true;
      let response = await api.loadDomainByName(this.domainName);
      this.domain = response.data;
      console.log("Loaded domain ", this.domain);
      this.loading = false;

    }
  },
};
</script>

<style></style>
